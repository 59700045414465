import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ConfiguracionMain from ".";
import LayoutSettings from "../../components/layout-settings";
import ManageShortName from "./company-short-names/manage-short-names";
import ShortName from "./company-short-names/short-name";
import ManageCurrency from "./currency/manage-currency";
import Currency from "./currency/currency";
import InfoCompany from "./info-company/info-company";
import ManageBranch from "./branch/manage-branch";
import Branch from "./branch/branch";
import ManageImage from "./images/manage-images";
import Image from "./images/image";
import ManageMarkers from "./markers/manage-markers";
import Marker from "./markers/marker";
import ManageSearchCriteria from "./search-criteria/manage-search-criteria";
import SearchCriteria from "./search-criteria/search-criteria";

const RouterSettings = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutSettings>
      <Switch>

        <Route exact path={`${path}/company-short-name/manage-short-name`}>
          <ManageShortName />
        </Route>
        <Route exact path={`${path}/company-short-name/short-name`}>
          <ShortName />
        </Route>

        <Route exact path={`${path}/currency/manage-currency`}>
          <ManageCurrency />
        </Route>
        <Route exact path={`${path}/currency/currency`}>
          <Currency />
        </Route>

        <Route exact path={`${path}/branch/manage-branch`}>
          <ManageBranch />
        </Route>
        <Route exact path={`${path}/branch/branch`}>
          <Branch />
        </Route>

        <Route exact path={`${path}/info-company/info-company`}>
          <InfoCompany />
        </Route>

        <Route exact path={`${path}`}>
          <ConfiguracionMain />
        </Route>

        <Route exact path={`${path}/images/manage-images`}>
          <ManageImage />
        </Route>
        <Route exact path={`${path}/images/image`}>
          <Image />
        </Route>

        <Route exact path={`${path}/markers/manage-markers`}>
          <ManageMarkers />
        </Route>
        <Route exact path={`${path}/markers/marker`}>
          <Marker />
        </Route>

        <Route exact path={`${path}/search-criteria/manage-search-criteria`}>
          <ManageSearchCriteria />
        </Route>
        <Route exact path={`${path}/search-criteria/search-criteria`}>
          <SearchCriteria />
        </Route>

      </Switch>
    </LayoutSettings>
  );
};

export default RouterSettings;
