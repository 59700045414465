import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ManageCoupons from ".";
import Coupon from "./coupon";
import ApplyCouponToBranches from "./applyCouponToBranches";


const RouterCoupon = () => {
  const { path } = useRouteMatch();
  // console.log('RouterCoupon');
  // console.log(path);
  return (
    // <LayoutSettings>
    <Switch>


      <Route exact path={`${path}`}>
        <ManageCoupons />
      </Route>
      <Route exact path={`${path}/coupon`}>
        <Coupon />
      </Route>
      <Route exact path={`${path}/apply-coupon-to-branches`}>
        <ApplyCouponToBranches />
      </Route>


    </Switch>
    // </LayoutSettings>
  );
};

export default RouterCoupon;
