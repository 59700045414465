import moment from "moment/moment";
import { POST, PUT, STRING_FAILURE, STRING_SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";
import { getBaseUrl } from "./env/env";

export async function getListImages(combo = false) {
    try {
        let rows = [];
        const { code, value } = await fetch(
            getBaseUrl() + "/image-company/get-list/",
            {
                headers: getHeaders(),
                method: POST,
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === STRING_SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < value.length; i++) {
                    const element = value[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.name;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                for (let i = 0; i < value.length; i++) {
                    value[i].dateUpdated = moment(value[i].dateUpdated).format('DD/MM/YYYY HH:mm')
                    value[i].defaultLabel = value[i].default === 1 ? "SI" : "NO";
                    rows.push(value[i]);
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("getListImages err");
        console.log(err);
        return [];
    }
}

export async function saveImageOnDB(obj) {
    try {
        let response = { error: true };
        const { code, message } = await fetch(
            getBaseUrl() + "/image-company/save-image/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            response.error = false
        }
        if (code === STRING_FAILURE) {
            response.message = message;
        }
        return response;
    } catch (err) {
        console.log("saveImageOnDB err");
        console.log(err);
        return { error: true };
    }
}

export async function deleteImageOnDB(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            getBaseUrl() + "/image-company/disable-image/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deleteImageOnDB err");
        console.log(err);
        return false;
    }
}

export async function setDefaultImageOnDB(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            getBaseUrl() + "/image-company/set-default-image/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("setDefaultImageOnDB err");
        console.log(err);
        return false;
    }
}

export async function removeDefaultImageOnDB(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            getBaseUrl() + "/image-company/remove-default-image/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === STRING_SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("removeDefaultImageOnDB err");
        console.log(err);
        return false;
    }
}
