import { ACCESSTOKENCONNECTME } from "../const/http-const";

export const sharedClearCredentials = () => {
    try {
        localStorage.clear(ACCESSTOKENCONNECTME);
    } catch (err) {
        console.log('sharedClearCredentials err');
        console.log(err);
    }
};

export const sharedNextPage = (page, params, history) => {
    try {
        // const history = useHistory();
        // history.push(`${page}`);
        console.log('params');
        console.log(params);
        if (params !== undefined && params !== null) {
            if (params.acciones !== undefined) {
                delete params.acciones;
            }
            console.log('params sin acciones');
            console.log(params);
            history.push({
                pathname: `${page}`,
                state: params
            });
        } else {
            history.push(`${page}`);
        }
    } catch (err) {
        console.log('nextPage err');
        console.log(err);
    }
};

export const obtenerMensajeConfirmar = (tituloDeshabilitar, rowSelected, attribute) => {
    try {
        if (rowSelected !== null) {
            return `${tituloDeshabilitar} ${rowSelected[attribute]}?`
        }
        return `${tituloDeshabilitar}?`
    } catch (err) {
        console.log('obtenerMensajeConfirmar err');
        console.log(err);
        return `${tituloDeshabilitar}?`
    }
}