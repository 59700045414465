import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_INPUT_GENERICO } from "../../utils/const/estilos-const";
import "../../style/style-cargando.css";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import { CheckCircle, ChevronLeft, ChevronsLeft, Eye, EyeOff, Facebook, Save, X, XCircle } from "feather-icons-react/build/IconComponents";
import { suscribirEmpresa } from "../../api/api-empresa.js";
import { GlobeAltIcon } from "@heroicons/react/24/outline";

export default function SuscribirseScreen() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [objectSelected, setObjectSelected] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [isHoveredBackButton, setIsHoveredBackButton] = useState(false);
  const [isHoveredCancelButton, setIsHoveredCancelButton] = useState(false);
  const [isHoveredSaveButton, setIsHoveredSaveButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  useEffect(() => {
    // console.log("entro a Correos");
    iniciar();
  }, []);

  const iniciar = () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setObjectSelected(data.params);
        setTitulo(data.titulo);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setObjectSelected((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      // console.log('handleSaveClick');
      // lógica para guardar los cambios
      console.log('objectSelected');
      console.log(objectSelected);
      // return;
      const creado = await suscribirEmpresa(objectSelected);
      console.log('creado');
      console.log(creado);
      if (creado.error === true) {
        alert(creado.message + "\n" + 'No se pudo crear');
      }
      if (creado.error === false) {
        alert('Se creo correctamente');
        history.push('/login');
      }

    } catch (err) {
      console.log('handleSaveClick');
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevConfirmShowPassword => !prevConfirmShowPassword);
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && objectSelected === null) {
        return sinAccesoCard();
      }
      return (
        <div className="min-h-screen bg-white-100 flex flex-col  py-6 xs:px-6 sm:px-6 md:px-6 lg:px-8">
          <div className="flex justify-between">
            <label className="font-bold text-gray-700">{titulo}</label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
              onMouseEnter={() => setIsHoveredBackButton(true)}
              onMouseLeave={() => setIsHoveredBackButton(false)}
            >
              {isHoveredBackButton ? (
                <ChevronsLeft className="mr-2 transition-transform duration-500 ease-in-out" />
              ) : (
                <ChevronLeft className="mr-2 transition-transform duration-500 ease-in-out" />
              )}
              ATRAS
            </button>
          </div>
          <div className="grid grid-cols-12 gap-4">

            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="block text-sm font-medium text-gray-700" htmlFor="nit">
                NIT
              </label>
              <input
                type="number"
                name="nit"
                value={objectSelected.nit}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="Enter company email"
                required
              />
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              {/* Company Name */}
              <label className="block text-sm font-medium text-gray-700" htmlFor="nameCompany">
                Nombre Empresa
              </label>
              <input
                type="text"
                name="nameCompany"
                value={objectSelected.nameCompany}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="Nombre Empresa"
                required
              />
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="block text-sm font-medium text-gray-700" htmlFor="phoneCompany">
                Número Telefono
              </label>
              <input
                type="tel"
                name="phoneCompany"
                value={objectSelected.phoneCompany}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="Número Telefono"
                required
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="block text-sm font-medium text-gray-700" htmlFor="addressCompany">
                Dirección
              </label>
              <input
                type="text"
                name="addressCompany"
                value={objectSelected.addressCompany}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="Dirección"
                required
              />
            </div>

          </div>
          <br />
          <hr className="mt-4 mb-4" />
          <label className="block text-sm font-bold text-gray-700">
            Redes Sociales
          </label>
          <br />
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="flex items-center text-sm font-medium text-gray-700" htmlFor="webSiteUrl">
                <GlobeAltIcon className="mr-2" width={24} height={24} />Web Site URL
              </label>
              <input
                type="url"
                name="webSiteUrl"
                value={objectSelected.webSiteUrl}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="https://"
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="flex items-center text-sm font-medium text-gray-700" htmlFor="facebookUrl">
                <Facebook className="mr-2" />Facebook
              </label>
              <input
                type="url"
                name="facebookUrl"
                value={objectSelected.facebookUrl}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="https://"
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="flex items-center text-sm font-medium text-gray-700" htmlFor="tiktokUrl">
                <GlobeAltIcon className="mr-2" width={24} height={24} />TikTok
              </label>
              <input
                type="url"
                name="tiktokUrl"
                value={objectSelected.tiktokUrl}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="https://"
              />
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <label className="block text-sm font-bold text-gray-700" htmlFor="websiteUrl">
            Información de Contacto
          </label>
          <br />
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="block text-sm font-medium text-gray-700" htmlFor="nameContact">
                Nombre Contacto
              </label>
              <input
                type="text"
                name="nameContact"
                value={objectSelected.nameContact}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="Nombre Contacto"
                required
              />
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
              <label className="block text-sm font-medium text-gray-700" htmlFor="emailContact">
                Email Contacto
              </label>
              <input
                type="email"
                name="emailContact"
                value={objectSelected.emailContact}
                onChange={handleInputChange}
                className={ESTILO_INPUT_GENERICO}
                placeholder="Email Contacto"
                required
              />
            </div>
          </div>
          {objectSelected !== null && objectSelected.id === 0 &&
            <>
              <hr className="mt-4 mb-4" />
              <label className="block text-sm font-bold text-gray-700" htmlFor="websiteUrl">
                Datos Usuario
              </label>
              <br />
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="user">
                    Nombre Usuario
                  </label>
                  <input
                    type="text"
                    name="user"
                    value={objectSelected.user}
                    onChange={handleInputChange}
                    className={ESTILO_INPUT_GENERICO}
                    placeholder="Nombre Usuario"
                    required
                  />
                </div>

                <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                    Contraseña
                  </label>
                  <input
                    type="email"
                    name="password"
                    value={objectSelected.password}
                    onChange={handleInputChange}
                    className={ESTILO_INPUT_GENERICO}
                    placeholder="Contraseña"
                    required
                  />
                </div>

                <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="confirmPassword">
                    Confirmar Contraseña
                  </label>
                  <input
                    type="email"
                    name="confirmPassword"
                    value={objectSelected.confirmPassword}
                    onChange={handleInputChange}
                    className={ESTILO_INPUT_GENERICO}
                    placeholder="Confirmar Contraseña"
                    required
                  />
                </div>
              </div>
            </>
          }
          <br />

          <div className="flex justify-between bg-white shadow-lg pt-4">
            <button
              type="button"
              className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleAtrasClick}
              onMouseEnter={() => setIsHoveredCancelButton(true)}
              onMouseLeave={() => setIsHoveredCancelButton(false)}
            >
              {isHoveredCancelButton ? (
                <XCircle className="mr-2 transition-transform duration-500 ease-in-out" />
              ) : (
                <X className="mr-2 transition-transform duration-500 ease-in-out" />
              )}
              CANCELAR
            </button>
            <button
              type="button"
              className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleSaveClick}
              onMouseEnter={() => setIsHoveredSaveButton(true)}
              onMouseLeave={() => setIsHoveredSaveButton(false)}
            >
              {isHoveredSaveButton ? (
                <CheckCircle className="mr-2 transition-transform duration-500 ease-in-out" />
              ) : (
                <Save className="mr-2 transition-transform duration-500 ease-in-out" />
              )}
              GUARDAR
            </button>
          </div>

        </div>
      );
    } catch (err) {
      console.log('ModificarCorreos render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
/*
<div className="grid grid-cols-12 gap-4 pt-2">

  <>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
      <div className="flex items-center">
        <span className="text-red-500 font-bold">*</span>
        <label className="font-bold ml-1">NOMBRE USUARIO</label>
      </div>
      <input
        id="nombreUsuario"
        name="nombreUsuario"
        type="text"
        value={objectSelected.nombreUsuario}
        onChange={handleInputChange}
        className={ESTILO_INPUT_GENERICO}
        required
        maxLength={255}
        autocomplete="off"
      />
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
      <div className="flex items-center">
        <span className="text-red-500 font-bold">*</span>
        <label className="font-bold ml-1">PASSWORD EMPRESA</label>
      </div>
      <div className="relative">
        <input
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={objectSelected.password}
          onChange={handleInputChange}
          className={`${ESTILO_INPUT_GENERICO} pr-10`}
          required
          maxLength={255}
          autocomplete="off"
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={togglePasswordVisibility}
        >
          <div
            className="transition-opacity duration-500"
            style={{ transitionDelay: '500ms' }}
          >
            {showPassword ? <EyeOff /> : <Eye />}
          </div>
        </button>
      </div>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
      <div className="flex items-center">
        <span className="text-red-500 font-bold">*</span>
        <label className="font-bold ml-1">CONFIRM PASSWORD EMPRESA</label>
      </div>
      <div className="relative">
        <input
          id="confirmPassword"
          name="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          value={objectSelected.confirmPassword}
          onChange={handleInputChange}
          className={`${ESTILO_INPUT_GENERICO} pr-10`}
          required
          maxLength={255}
          autocomplete="off"
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={toggleConfirmPasswordVisibility}
        >
          <div
            className="transition-opacity duration-500"
            style={{ transitionDelay: '500ms' }}
          >
            {showConfirmPassword ? <EyeOff /> : <Eye />}
          </div>
        </button>
      </div>
    </div>
  </>

</div>*/