import { INT_TRUE, INT_TYPE_DISCOUNT_PERCENTAGE } from "../utils/const/int-const";
import CouponCard from "./coupon-card";

const ModalSeeCoupon = ({ isOpen, onCancel, rowSelected }) => {
    // console.log('ModalSeeCoupon');
    // console.log(rowSelected);
    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="py-4 text-left px-6">
                            <CouponCard
                                bgColor={rowSelected.color}
                                discount={rowSelected.idTypeDiscount === INT_TYPE_DISCOUNT_PERCENTAGE ? "%" : rowSelected.abbreviationCurrency}
                                discountValue={rowSelected.valueDiscount}
                                nameCoupon={rowSelected.nameCoupon}
                                prefixCoupon={rowSelected.prefixCode}
                                numberCoupon={rowSelected.numberCoupon}
                                codeCoupon={rowSelected.codeCoupon}
                                dateBegin={rowSelected.dateBegin}
                                dateEnd={rowSelected.dateEnd}
                                description={rowSelected.description}
                                sundayChecked={rowSelected.sundayChecked === INT_TRUE ? true : false}
                                mondayChecked={rowSelected.mondayChecked === INT_TRUE ? true : false}
                                tuesdayChecked={rowSelected.tuesdayChecked === INT_TRUE ? true : false}
                                wednesdayChecked={rowSelected.wednesdayChecked === INT_TRUE ? true : false}
                                thursdayChecked={rowSelected.thursdayChecked === INT_TRUE ? true : false}
                                fridayChecked={rowSelected.fridayChecked === INT_TRUE ? true : false}
                                saturdayChecked={rowSelected.saturdayChecked === INT_TRUE ? true : false}
                                selectedImage={null}
                                urlImage={rowSelected.urlImage}
                            />
                            <div className="flex justify-end">
                                <button
                                    className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={onCancel}
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalSeeCoupon;