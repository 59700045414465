import { POST, STRING_ERROR, STRING_FAILURE, STRING_SUCCESS, } from "../utils/const/http-const";
import { getHeaders } from "./utils";
import { getBaseUrl } from "./env/env";

export async function crearSectorCompany(obj) {
    try {
        let response = { error: true, message: '' };
        const { message, code } = await fetch(
            getBaseUrl() + "/sector-company/create-sector-company",
            {
                headers: getHeaders(true),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log("message");
        // console.log(message);
        // console.log("code");
        // console.log(code);
        if (code === STRING_ERROR || code === STRING_FAILURE) {
            response.error = true;
            response.message = message;
        }
        if (code === STRING_SUCCESS) {
            response.error = false;
        }
        return response;
    } catch (err) {
        console.log("crearSectorCompany err");
        console.log(err);
        return { error: true, message: err };
    }
}

export async function getListSectorCompany() {
    try {
        let response = [];
        const { value, code } = await fetch(
            getBaseUrl() + "/sector-company/get-list-sector-company",
            {
                headers: getHeaders(),
                method: POST,
                // body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log("message");
        // console.log(message);
        // console.log("code");
        // console.log(code);
        if (code === STRING_SUCCESS) {
            response = value;
        }
        return response;
    } catch (err) {
        console.log("getListSectorCompany err");
        console.log(err);
        return [];
    }
}

export async function disableSectorCompany(obj) {
    try {
        let response = { error: true, message: '' };
        const { message, code } = await fetch(
            getBaseUrl() + "/sector-company/disable-sector-company",
            {
                headers: getHeaders(true),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log("message");
        // console.log(message);
        // console.log("code");
        // console.log(code);
        if (code === STRING_ERROR || code === STRING_FAILURE) {
            response.error = true;
            response.message = message;
        }
        if (code === STRING_SUCCESS) {
            response.error = false;
        }
        return response;
    } catch (err) {
        console.log("disableSectorCompany err");
        console.log(err);
        return { error: true, message: err };
    }
}