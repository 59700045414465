import { ACCESSTOKENCONNECTME } from "../utils/const/http-const";
import { getBaseUrl } from "./env/env";

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const baseUrl = getBaseUrl();

export const getHeaders = (auth = true) => {
  if (auth === true) {
    const bearer = localStorage.getItem(ACCESSTOKENCONNECTME);
    if (typeof bearer !== "string") throw new Error("missing bearer token");
    return {
      ...HEADERS,
      Authorization: `Bearer ${bearer}`,
    };
  }
  if (auth === false) {
    return {
      ...HEADERS,
    };
  }

};
