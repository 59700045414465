export const STRING_SUCCESS = "SUCCESS";
export const STRING_FAILURE = "FAILURE";
export const STRING_ERROR = "ERROR";

export const JWT_EXPIRED = "jwt expired";

export const POST = "POST";
export const GET = "GET";
export const DELETE = "DELETE";
export const PUT = "PUT";

export const ACCESSTOKENCONNECTME = "ACCESSTOKENCONNECTME";