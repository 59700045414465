import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sharedClearCredentials } from "../utils/shared-functions/sharedFunctions";

export default function Salir() {
  const history = useHistory();
  const clearData = (page) => {
    try {
      console.log('clearData');

      history.push(page);

      console.log('end clearData');
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    // console.log('useEffect');
    sharedClearCredentials();
    clearData("landing");
    // console.log('end useEffect');
  }, []);

  return <div className="p-[2rem]"></div>;
}
