import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../../utils/const/estilos-const";
import ModalConfirm from "../../../components/modal-confirm";
import "../../../style/style-cargando.css";
import cargandoCard from "../../../components/cargando-card";
import sinAccesoCard from "../../../components/sin-acceso-card";
import { Settings } from "feather-icons-react/build/IconComponents";
import { sharedNextPage } from "../../../utils/shared-functions/sharedFunctions";
import { deleteMarkerOnDB, getListMarkers, removeDefaultMarkerOnDB, setDefaultMarkerOnDB } from "../../../api/api-markers";

// const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
// let accesoBotonNuevo = 'none';


export default function ManageMarkers() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "ID", visible: false, },
    { key: "name", title: "NOMBRE", visible: true, filter: true, editLabel: true, },
    { key: "default", title: "DEFAULT", visible: false, filter: true, editLabel: true, },
    { key: "defaultLabel", title: "DEFAULT", visible: true, filter: true, editLabel: true, },
    { key: "url", title: "URL", visible: true, filter: true, editLabel: true, },
    { key: "dateUpdated", title: "FECHA PROCESO", visible: true, filter: true },
  ];
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listElements, setListElements] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [elementoSelected, setElementoSelected] = useState(null);
  // const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);
  const [modalSetDefault, setModalSetDefault] = useState(false);
  const [modalRemoveDefault, setModalRemoveDefault] = useState(false);
  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      /*let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      */
      let permiso = null;
      let lista = [];

      // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      // accesoBotonNuevo = '';
      setTieneAccesoAlModulo(true);
      lista = await getListMarkers(false);
      // }
      // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      //   if (permiso.accesoAlModulo === TRUE) {
      //     setTieneAccesoAlModulo(true);
      //     lista = await getListCurrencies(false);
      //   }
      // }

      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      console.log('agregarAccionesALista');
      console.log(lista);
      if (lista.length === 0) {
        setListElements(lista);
      }
      if (lista.length > 0) {
        const objDeshabilitar = {
          label: "Eliminar",
          color: "red",
          onClick: (param) => changeStatus(param),
        };
        const objSetDefault = {
          label: "Set Default",
          color: "green",
          onClick: (param) => changeSetDefault(param),
        };
        const objRemoveDefault = {
          label: "Remove Default",
          color: "green",
          onClick: (param) => changeRemoveDefault(param),
        };
        // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        // if (permiso.nuevo === TRUE) {
        // accesoBotonNuevo = '';
        // }
        // }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          // acciones.push(objModificar);          
          acciones.push(objDeshabilitar);
          if (lista[i].default === 0) {
            acciones.push(objSetDefault);
          }
          if (lista[i].default === 1) {
            acciones.push(objRemoveDefault);
          }
          // }
          // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          // if (permiso.modificar === TRUE) {
          // acciones.push(objModificar);
          // }
          // if (permiso.eliminarDeshabilitar === TRUE) {
          // acciones.push(objDeshabilitar);
          // }
          // }
          lista[i].acciones = acciones;
        }
        setListElements(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const changeStatus = (data) => {
    try {
      console.log("changeStatus");
      console.log(data);
      setElementoSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("changeStatus err");
      console.log(err);
    }
  };

  const changeSetDefault = (data) => {
    try {
      console.log("changeSetDefault");
      console.log(data);
      setElementoSelected(data);
      setModalSetDefault(true);
    } catch (err) {
      console.log("changeSetDefault err");
      console.log(err);
    }
  };

  const changeRemoveDefault = (data) => {
    try {
      console.log("changeSetDefault");
      console.log(data);
      setElementoSelected(data);
      setModalRemoveDefault(true);
    } catch (err) {
      console.log("changeSetDefault err");
      console.log(err);
    }
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.name = "";
    data.url = "";

    obj.title = "NUEVO MARKER";
    obj.params = data;
    sharedNextPage("marker", obj, history);
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (elementoSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = elementoSelected.id;
    if (elementoSelected.id !== 0) {
      // console.log('crear correo');
      const disabled = await deleteMarkerOnDB(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (disabled === true) {
        alert('Se elimino correctamente');
        reset();
      }
      if (disabled === false) {
        alert('No se pudo eliminar');
      }
    }
  }

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
  };

  const handleConfirmSetDefault = async () => {
    setModalSetDefault(false);
    if (elementoSelected == null) {
      return;
    }
    // console.log('elementoSelected');
    // console.log(elementoSelected);
    // return;
    let objEnviar = {};
    objEnviar.id = elementoSelected.id;
    if (elementoSelected.id !== 0) {
      // console.log('crear correo');
      const disabled = await setDefaultMarkerOnDB(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (disabled === true) {
        alert('Se selecciono como default correctamente');
        reset();
      }
      if (disabled === false) {
        alert('No se pudo realizar el cambio');
      }
    }
  };

  const handleRemoveDefault = async () => {
    setModalRemoveDefault(false);
    if (elementoSelected == null) {
      return;
    }
    // console.log('elementoSelected');
    // console.log(elementoSelected);
    // return;
    let objEnviar = {};
    objEnviar.id = elementoSelected.id;
    if (elementoSelected.id !== 0) {
      // console.log('crear correo');
      const disabled = await removeDefaultMarkerOnDB(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (disabled === true) {
        alert('Se cambio estado correctamente');
        reset();
      }
      if (disabled === false) {
        alert('No se pudo realizar el cambio');
      }
    }
  };

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (tieneAccesoAlModulo === false) {
        return sinAccesoCard();
      }
      return (
        <div>
          <div className="pt-4 px-4 flex justify-between">
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              GESTIONAR MARKERS
            </label>
            <div className="flex justify-center">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  // display: accesoBotonNuevo,
                  marginRight: '2px'
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
                style={{
                  marginRight: '2px'
                }}
              >
                ATRAS
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                <Settings />
              </button>
            </div>
          </div>
          <Grid rows={listElements} headers={headers} />
          <ModalConfirm
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás seguro de eliminar Imagen?"
          />
          <ModalConfirm
            isOpen={modalSetDefault}
            onCancel={() => { setModalSetDefault(false) }}
            onConfirm={handleConfirmSetDefault}
            message="¿Estás seguro de seleccionar como marker Principal/Default?"
          />
          <ModalConfirm
            isOpen={modalRemoveDefault}
            onCancel={() => { setModalRemoveDefault(false) }}
            onConfirm={handleRemoveDefault}
            message="¿Estás seguro de quitar marker Principal/Default?"
          />
        </div>
      );
    } catch (err) {
      console.log('ManageCurrency render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
