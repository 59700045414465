import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Layout from "../components/layout";
import RouterSettings from "./settings/router";
import DashboardScreen from "./dashboard";
import Salir from "./salir";
import RouterCoupon from "./coupon/router";
// import RouterUsuarios from "./usuarios/router";

const RouterPage = () => {
  const { path } = useRouteMatch();
  // console.log('RouterPage');
  // console.log(path);
  return (
    <Switch>
      <Layout>
        <Route exact path={`${path}`}>
          <DashboardScreen />
        </Route>
        <Route path={`${path}managecoupons`}>
          <RouterCoupon />
        </Route>
        <Route path={`${path}settings`}>
          <RouterSettings />
        </Route>
        <Route path={`${path}users`}>
          {/* <RouterUsuarios /> */}
        </Route>
        <Route path={`${path}logout`}>
          <Salir />
        </Route>
      </Layout>
    </Switch>
  );
};

export default RouterPage;
