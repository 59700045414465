import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
// import Login from "./page/login/login";
import RouterPage from "./page/router";
import LandingScreen from "./page/landingScreen/landing-screen";
import LoginScreen from "./page/login/login";
import SuscribirseScreen from "./page/login/suscribirse";

const RouterMain = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/landing">
        <LandingScreen />
      </Route>
      <Route exact path="/login">
        <LoginScreen />
      </Route>
      <Route exact path="/suscribirse">
        <SuscribirseScreen />
      </Route>
      <Route path="/">
        <RouterPage />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default RouterMain;
