export const OBJECT = "object";
export const ACCIONES = "acciones";
export const CORREOS = "correos";
export const GEOCERCAS = "geocercas";
export const COLOR = "color";
export const COLOR_A_TIEMPO = "coloratiempo";
export const COLOR_RETRASO = "colorretraso";
export const UBICACION_GPS = "ubicaciongps";
export const CHECK = "check";
export const URL = "url";
//-----------------
export const PERCENTAGE = "%";