import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const.js";
import "../../../style/style-cargando.css";
import cargandoCard from "../../../components/cargando-card.js";
import { CheckCircle, ChevronLeft, ChevronsLeft, Save, X, XCircle } from "feather-icons-react/build/IconComponents";
import moment from "moment";
import { saveImageCoupon } from "../../../api/api-coupon.js";
import { saveImageOnDB } from "../../../api/api-images.js";

export default function Image() {
    const locationPath = useLocation();
    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [objectSelected, setObjectSelected] = useState(null);
    const [isHoveredBackButton, setIsHoveredBackButton] = useState(false);
    const [isHoveredCancelButton, setIsHoveredCancelButton] = useState(false);
    const [isHoveredSaveButton, setIsHoveredSaveButton] = useState(false);
    const [title, setTitle] = useState("");

    const [counter, setCounter] = useState(0);

    const [selectedImage, setSelectedImage] = useState(null);
    const [formatError, setFormatError] = useState(false);
    const [sizeError, setSizeError] = useState(false);

    useEffect(() => {
        // console.log("entro a Correos");
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = locationPath.state;
            console.log("data");
            console.log(data);
            if (data !== undefined && data !== null) {
                setObjectSelected(data.params);
                setTitle(data.title);
            }

            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setObjectSelected((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSaveClick = async () => {
        try {
            // console.log('handleSaveClick');
            // lógica para guardar los cambios
            console.log('objectSelected');
            console.log(objectSelected);
            if (String(objectSelected.name).trim().length === 0) {
                alert('Debe ingresar nombre imagen');
                return;
            }
            if (selectedImage === null) {
                alert('Debe importar imagen');
                return;
            }
            if (objectSelected.id === 0) {
                saveImage(objectSelected);
            }
        } catch (err) {
            console.log('handleSaveClick');
            console.log(err);
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        try {
            // console.log('handleDrop');
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            uploadImage(file);
        } catch (err) {
            console.log('handleDrop err');
            console.log(err);
        }
    };

    const handleImageUpload = (e) => {
        try {
            // console.log('handleImageUpload');
            const file = e.target.files[0];
            uploadImage(file);
        } catch (err) {
            console.log('handleImageUpload err');
            console.log(err);
        }
    };

    const uploadImage = (file) => {
        // console.log('uploadImage');
        // console.log(file);
        if (file) {
            // console.log('file size');
            // console.log(file.size);
            // console.log('1024-----');
            // console.log(1024 * 1024);
            if (file.size > 512 * 1024) {
                setSizeError(true);
            } else if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                setSizeError(false);
                setFormatError(false);
                setSelectedImage(URL.createObjectURL(file));
                setObjectSelected({ ...objectSelected, url: null });
            } else {
                setFormatError(true);
            }
        }
    };

    const handleDeleteImage = () => {
        setSelectedImage(null);
        setFormatError(false);
        setSizeError(false);
    };

    const saveImage = async () => {
        try {
            // console.log('handleSaveImage');
            // console.log('selectedImage');
            // console.log(selectedImage);
            setCounter(1);
            const imgElement = document.createElement("img");
            imgElement.src = selectedImage;
            imgElement.onload = async () => {
                const canvas = document.createElement("canvas");
                canvas.width = imgElement.width;
                canvas.height = imgElement.height;
                const context = canvas.getContext("2d");
                context.drawImage(imgElement, 0, 0);
                const base64Image = canvas.toDataURL("image/png");
                let fileType = base64Image.match(/^data:(.+);base64,/)[1];
                fileType = removePrefix(fileType, 'image/');

                let objEnviar = {};
                objEnviar.base64 = base64Image;
                objEnviar.fileName = moment().unix() + '.' + fileType;
                const saveInTheCloud = await saveImageCoupon(objEnviar);
                // console.log('saveInTheCloud');
                // console.log('saveInTheCloud');
                // console.log(saveInTheCloud);
                if (saveInTheCloud.error === false) {
                    console.log('se guardo imagen, guardar en bd');
                    // setObjectSelected({ ...objectSelected, url: saveInTheCloud.value });
                    let objSet = { ...objectSelected };
                    objSet.url = saveInTheCloud.value;
                    // setSelectedImage(null);
                    // nextStep();
                    // console.log('objSet');
                    // console.log(objSet);
                    const savedImage = await saveImageOnDB(objSet);
                    if (savedImage.error === true) {
                        alert(savedImage.message);
                        setCounter(0);
                        return;
                    }
                    if (savedImage.error === false) {
                        alert('Se guardo correctamente');
                        handleAtrasClick();
                    }
                }
                if (saveInTheCloud.error === true) {
                    setCounter(0);
                    alert('No se pudo guardar imagen, por favor revise que la resolucion no sea mayor a 566x360');
                    return;
                }
                setCounter(0);
            };
        } catch (err) {
            console.log('handleSaveImage err');
            console.log(err);
        }
    };

    const removePrefix = (string, prefix) => {
        return string.replace(prefix, '');
    };

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            return (
                <div className="min-h-screen bg-white-100 flex flex-col  py-6 xs:px-6 sm:px-6 md:px-6 lg:px-8">
                    <div className="flex justify-between">
                        <label className="font-bold text-gray-700">{title}</label>
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleAtrasClick}
                            onMouseEnter={() => setIsHoveredBackButton(true)}
                            onMouseLeave={() => setIsHoveredBackButton(false)}
                        >
                            {isHoveredBackButton ? (
                                <ChevronsLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <ChevronLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            ATRAS
                        </button>
                    </div>
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            {/* Company Name */}
                            <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                                Nombre Imagen
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={objectSelected.name}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Nombre Imagen"
                                required
                            />
                        </div>

                    </div>
                    <hr className="mt-4 mb-4" />
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-3 lg:col-span-4 xl:col-span-3">
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-6">
                            {/* imagen */}
                            <div className="mt-4">
                                <label htmlFor="imageUpload" className="block text-lg font-medium text-gray-700 mb-2">
                                    Subir imagen:
                                </label>
                                <div
                                    id="dropZone"
                                    className={`w-70vw h-30vh border-2 border-gray-300 border-dashed rounded-md flex items-center justify-center ${selectedImage ? 'hidden' : ''
                                        }`}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <input
                                        id="imageUpload"
                                        type="file"
                                        className="hidden"
                                        onChange={handleImageUpload}
                                        accept=".jpg, .png"
                                    />
                                    <div className="text-center">
                                        <p className="text-sm text-gray-500">Arrastra y suelta la imagen aquí o</p>
                                        <button
                                            type="button"
                                            className="mt-2 py-2 px-4 border border-gray-300 rounded-md bg-red-300 text-gray-700 font-medium hover:bg-red-400"
                                            onClick={() => document.getElementById('imageUpload').click()}
                                        >
                                            Subir imágenes
                                        </button>
                                    </div>
                                </div>
                                <label>Subir imagenes de tipo .png o .jpg<label className="text-xs"> (las imagenes deben ser menor a 1 MB)</label></label>
                                {formatError && (
                                    <p className="text-red-500 text-sm mt-2">Formato de archivo no válido. Por favor, selecciona un archivo .jpg o .png.</p>
                                )}
                                {sizeError && (
                                    <p className="text-red-500 text-sm mt-2">El tamaño del archivo excede el límite de 512 KB.</p>
                                )}
                            </div>

                            {selectedImage && (
                                <div className="mt-4">
                                    <img src={selectedImage} alt="Preview" className="w-full rounded-md" />
                                    <div className="flex justify-between mt-2">
                                        <button
                                            type="button"
                                            className="py-1 px-2 bg-red-300 text-white font-medium rounded hover:bg-red-400"
                                            onClick={handleDeleteImage}
                                        >
                                            Eliminar imagen
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col-span-12 md:col-span-3 lg:col-span-4 xl:col-span-3">
                        </div>

                    </div>

                    <div className="flex justify-between bg-white shadow-lg pt-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_CANCELAR}
                            onClick={handleAtrasClick}
                            onMouseEnter={() => setIsHoveredCancelButton(true)}
                            onMouseLeave={() => setIsHoveredCancelButton(false)}
                        >
                            {isHoveredCancelButton ? (
                                <XCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <X className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            CANCELAR
                        </button>
                        {counter === 0 ?
                            <button
                                type="button"
                                className={ESTILO_BOTON_GENERAL_ACEPTAR}
                                onClick={handleSaveClick}
                                onMouseEnter={() => setIsHoveredSaveButton(true)}
                                onMouseLeave={() => setIsHoveredSaveButton(false)}
                            >
                                {isHoveredSaveButton ? (
                                    <CheckCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                                ) : (
                                    <Save className="mr-2 transition-transform duration-500 ease-in-out" />
                                )}
                                GUARDAR
                            </button>
                            :
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                            >
                                <svg
                                    className="animate-spin h-5 w-5 text-white mx-auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    />
                                </svg>
                            </button>
                        }
                    </div>
                </div>
            );
        } catch (err) {
            console.log('ModificarCorreos render err');
            console.log(err);
            return (
                <div>
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}