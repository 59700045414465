import moment from "moment/moment";
import { POST, PUT, STRING_SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";
import { getBaseUrl } from "./env/env";

export async function getListConcept(obj, combo = false) {
    try {
        let rows = [];
        const { code, value } = await fetch(
            getBaseUrl() + "/concept/get-list-concept/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === STRING_SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < value.length; i++) {
                    const element = value[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.description;
                    rows.push(obj);
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("getListCurrencies err");
        console.log(err);
        return [];
    }
}
