import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const.js";
import "../../../style/style-cargando.css";
import cargandoCard from "../../../components/cargando-card.js";
import { CheckCircle, ChevronLeft, ChevronsLeft, Save, X, XCircle } from "feather-icons-react/build/IconComponents";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { createBranch, editBranchInfo } from "../../../api/api-branch.js";

// Ensure default marker icon is loaded
L.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

export default function Branch() {
    const locationPath = useLocation();
    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [objectSelected, setObjectSelected] = useState(null);
    const [isHoveredBackButton, setIsHoveredBackButton] = useState(false);
    const [isHoveredCancelButton, setIsHoveredCancelButton] = useState(false);
    const [isHoveredSaveButton, setIsHoveredSaveButton] = useState(false);
    const [title, setTitle] = useState("");

    const [position, setPosition] = useState(null); // Marker position (lat, lng)
    const [location, setLocation] = useState({ latitude: -17.783420, longitude: -63.181049 });// Inputs for lat and lng

    useEffect(() => {
        // console.log("entro a Correos");
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = locationPath.state;
            console.log("data");
            console.log(data);
            if (data !== undefined && data !== null) {
                setObjectSelected(data.params);
                setTitle(data.title);
            }

            if (data.params.latitude === null && data.params.longitude === null) {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLocation({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                        },
                        (error) => {
                            console.log('error location');
                            console.log(error);
                        }
                    );
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }
            } else {
                setPosition([data.params.latitude, data.params.longitude]);
                setLocation({
                    latitude: data.params.latitude,
                    longitude: data.params.longitude
                });
            }

            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setObjectSelected((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSaveClick = async () => {
        try {
            // console.log('handleSaveClick');
            // lógica para guardar los cambios
            console.log('objectSelected');
            console.log(objectSelected);
            if (objectSelected.id === 0) {
                const response = await createBranch(objectSelected);
                console.log('response');
                console.log(response);
                if (response.error === true) {
                    alert(response.message + "\n" + 'No se pudo crear');
                }
                if (response.error === false) {
                    alert('Se creo correctamente');
                    handleAtrasClick();
                }
            } else {
                const response = await editBranchInfo(objectSelected);
                console.log('response');
                console.log(response);
                if (response.error === true) {
                    alert(response.message + "\n" + 'No se pudo actualizar');
                }
                if (response.error === false) {
                    alert('Se actualizo correctamente');
                    handleAtrasClick();
                }
            }


        } catch (err) {
            console.log('handleSaveClick');
            console.log(err);
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    }

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setPosition([lat, lng]);
                setLocation({ latitude: lat.toFixed(10), longitude: lng.toFixed(10) });
                setObjectSelected({ ...objectSelected, latitude: lat.toFixed(10), longitude: lng.toFixed(10) });
            },
        });

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You clicked here</Popup>
            </Marker>
        );
    };

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            return (
                <div className="min-h-screen bg-white-100 flex flex-col  py-6 xs:px-6 sm:px-6 md:px-6 lg:px-8">
                    <div className="flex justify-between">
                        <label className="font-bold text-gray-700">{title}</label>
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleAtrasClick}
                            onMouseEnter={() => setIsHoveredBackButton(true)}
                            onMouseLeave={() => setIsHoveredBackButton(false)}
                        >
                            {isHoveredBackButton ? (
                                <ChevronsLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <ChevronLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            ATRAS
                        </button>
                    </div>
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            {/* Company Name */}
                            <label className="block text-sm font-medium text-gray-700" htmlFor="nameBranch">
                                Nombre Sucursal
                            </label>
                            <input
                                type="text"
                                name="nameBranch"
                                value={objectSelected.nameBranch}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Nombre Sucursal"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="phoneBranch">
                                Número Telefono
                            </label>
                            <input
                                type="tel"
                                name="phoneBranch"
                                value={objectSelected.phoneBranch}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Número Telefono"
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="addressBranch">
                                Dirección
                            </label>
                            <input
                                type="text"
                                name="addressBranch"
                                value={objectSelected.addressBranch}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Dirección"
                                required
                            />
                        </div>

                    </div>

                    <hr className="mt-4 mb-4" />
                    <label className="block text-sm font-bold text-gray-700" htmlFor="websiteUrl">
                        Información de Contacto
                    </label>
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="nameContact">
                                Nombre Contacto
                            </label>
                            <input
                                type="text"
                                name="nameContact"
                                value={objectSelected.nameContact}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Nombre Contacto"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="emailContact">
                                Email Contacto
                            </label>
                            <input
                                type="email"
                                name="emailContact"
                                value={objectSelected.emailContact}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Email Contacto"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="phoneContact">
                                Telefono Contacto
                            </label>
                            <input
                                type="email"
                                name="phoneContact"
                                value={objectSelected.phoneContact}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Email Contacto"
                                required
                            />
                        </div>
                    </div>
                    <hr className="mt-4 mb-4" />
                    <label className="block text-sm font-bold text-gray-700" htmlFor="websiteUrl">
                        Coordenadas
                    </label>
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="latitude">
                                Latitud
                            </label>
                            <input
                                type="text"
                                name="latitude"
                                value={objectSelected.latitude}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Latitud"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="longitude">
                                Longitud
                            </label>
                            <input
                                type="text"
                                name="longitude"
                                value={objectSelected.longitude}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Longitud"
                                required
                            />
                        </div>
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12">
                            <MapContainer center={[location.latitude, location.longitude]} zoom={13} style={{ height: "50vh", width: "100%" }}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <LocationMarker />
                            </MapContainer>
                        </div>
                    </div>
                    <br />
                    <div className="flex justify-between bg-white shadow-lg pt-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_CANCELAR}
                            onClick={handleAtrasClick}
                            onMouseEnter={() => setIsHoveredCancelButton(true)}
                            onMouseLeave={() => setIsHoveredCancelButton(false)}
                        >
                            {isHoveredCancelButton ? (
                                <XCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <X className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            CANCELAR
                        </button>
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_ACEPTAR}
                            onClick={handleSaveClick}
                            onMouseEnter={() => setIsHoveredSaveButton(true)}
                            onMouseLeave={() => setIsHoveredSaveButton(false)}
                        >
                            {isHoveredSaveButton ? (
                                <CheckCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <Save className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            GUARDAR
                        </button>
                    </div>
                </div>
            );
        } catch (err) {
            console.log('ModificarCorreos render err');
            console.log(err);
            return (
                <div>
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}