export const INT_TIPO_USUARIO_ADMINISTRADOR_WEB = 10;
export const INT_TIPO_SUB_USUARIO_WEB = 11;

export const INT_TYPE_DISCOUNT_MONEY = 2;
export const INT_TYPE_DISCOUNT_PERCENTAGE = 3;

export const INT_STATUS_BAJA = 12;
export const INT_STATUS_ACTIVO = 14;
export const INT_STATUS_DESACTIVADO = 15;

export const INT_TRUE = 1;
export const INT_FALSE = 0;

export const INT_QUERY_INSERT = 1;
export const INT_QUERY_UPDATE = 2;

export const TIPO_USUARIO_WEB = {
    USUARIO_SUPER_ADMIN: 2,
    USUARIO_EMPRESA: 3,
    USUARIO_SUCURSAL: 4,
}