import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const/estilos-const";
import ModalConfirm from "../../components/modal-confirm";
import "../../style/style-cargando.css";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import { sharedNextPage } from "../../utils/shared-functions/sharedFunctions";
import { addCouponToBranch, disableCouponToBranch, getBranchesWhereCouponApply, isCouponAlreadyAddedToBranch } from "../../api/api-coupon";
import { getListBranches } from "../../api/api-branch";
import ModalAddBranchCoupon from "../../components/modal-add-branch-coupon";

// const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
// let accesoBotonNuevo = 'none';


export default function ApplyCouponToBranches() {
    const headers = [
        { key: "acciones", title: "ACCIONES" },
        { key: "id", title: "ID", visible: false, },
        { key: "nameBranch", title: "NOMBRE SUCURSAL", visible: true, filter: true, editLabel: true, },
        { key: "idCoupon", title: "ID COUPON", visible: false, filter: true, editLabel: true, },
        { key: "nameCoupon", title: "NOMBRE CUPON", visible: true, filter: true, },
        { key: "codeCoupon", title: "CODIGO CUPON", visible: false, filter: true, },
        { key: "dateUpdated", title: "FECHA PROCESO", visible: true, filter: true },
    ];
    const location = useLocation();
    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [listElements, setListElements] = useState([]);
    const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
    const [elementoSelected, setElementoSelected] = useState(null);
    // const [permisos, setPermisos] = useState(null);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);
    const [title, setTitle] = useState("");

    const [listBranches, setListBranches] = useState([]);
    const [branchSelected, setBranchSelected] = useState(null);
    const [modalAddBranch, setModalAddBranch] = useState(false);
    const [infoCoupon, setInfoCoupon] = useState(null);
    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            /*let obj = {};
            obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
            obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
            obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            */
            const data = location.state;
            console.log("data");
            console.log(data);
            setTitle(data.title);
            setInfoCoupon(data.params);
            let permiso = null;
            let lista = [];

            // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            // accesoBotonNuevo = '';            
            let objSend = { idCoupon: data.params.id };
            setTieneAccesoAlModulo(true);
            lista = await getBranchesWhereCouponApply(objSend);
            const branches = await getListBranches(true);
            setListBranches(branches);
            // }
            // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            //   if (permiso.accesoAlModulo === TRUE) {
            //     setTieneAccesoAlModulo(true);
            //     lista = await getListCurrencies(false);
            //   }
            // }

            agregarAccionesALista(lista, permiso);
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const agregarAccionesALista = (lista, permiso) => {
        try {
            console.log('agregarAccionesALista');
            console.log(lista);
            if (lista.length === 0) {
                setListElements(lista);
            }
            if (lista.length > 0) {
                // const objModificar = {
                //     label: "Modificar",
                //     color: "green",
                //     onClick: (param) => editElementSelected(param),
                // };
                const objDeshabilitar = {
                    label: "Deshabilitar",
                    color: "red",
                    onClick: (param) => changeStatus(param),
                };
                // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                // if (permiso.nuevo === TRUE) {
                // accesoBotonNuevo = '';
                // }
                // }
                for (let i = 0; i < lista.length; i++) {
                    let acciones = [];
                    // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                    // acciones.push(objModificar);
                    acciones.push(objDeshabilitar);
                    // }
                    // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                    // if (permiso.modificar === TRUE) {
                    // acciones.push(objModificar);
                    // }
                    // if (permiso.eliminarDeshabilitar === TRUE) {
                    // acciones.push(objDeshabilitar);
                    // }
                    // }
                    lista[i].acciones = acciones;
                }
                setListElements(lista);
            }
        } catch (err) {
            console.log('agregarAccionesALista err');
            console.log(err);
        }
    }

    const changeStatus = async (data) => {
        try {
            console.log("changeStatus");
            console.log(data);
            setElementoSelected(data);
            setModalConfirmarDeshabilitarVisible(true);
        } catch (err) {
            console.log("changeStatus err");
            console.log(err);
        }
    };

    const handleAgregarNuevaSucursal = () => {
        setBranchSelected(null);
        setModalAddBranch(true);
    }

    const handleAtrasClick = () => {
        history.goBack();
    }

    const reset = async () => {
        setCargandoPage(true);
        iniciar();
    }

    const handleConfirmModal = async () => {
        setModalConfirmarDeshabilitarVisible(false);
        if (elementoSelected == null) {
            return;
        }
        let objEnviar = {};
        objEnviar.id = elementoSelected.id;
        if (elementoSelected.id !== 0) {
            const disabled = await disableCouponToBranch(objEnviar);
            // console.log('creado');
            // console.log(creado);
            if (disabled === true) {
                alert('Se deshabilito correctamente');
                reset();
            }
            if (disabled === false) {
                alert('No se pudo deshabilitar');
            }
        }
    };

    const handleConfirmModalAddBranch = async () => {
        try {
            setModalAddBranch(false);
            console.log(branchSelected);
            if (branchSelected === null) {
                alert('Debe seleccionar Sucursal');
                return;
            }
            if (infoCoupon === null) {
                alert('No existe info cupon');
                return;
            }
            let objSend = {};
            objSend.idBranch = branchSelected.value;
            objSend.idCoupon = infoCoupon.id;
            const count = await isCouponAlreadyAddedToBranch(objSend);
            // console.log('count');
            // console.log(count);
            // return;
            if (count !== 0) {
                alert('El cupon ya fue agregado a esta sucursal');
                return;
            }
            const response = await addCouponToBranch(objSend);
            if (response === true) {
                alert('Se agrego correctamente');
                reset();
            }
            if (response === false) {
                alert('No se pudo procesar, intente nuevamente');
            }
        } catch (err) {
            console.log('handleConfirmModalAddBranch err');
            console.log(err);
        }
    };

    const render = () => {
        try {
            // console.log('listar correo render');
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div>
                    <div className="pt-4 px-4 flex justify-between">
                        <label
                            style={{
                                paddingLeft: 10,
                                fontWeight: 'bold',
                            }}
                        >
                            GESTIONAR SUCURSALES DONDE APLICA EL CUPON - {title}
                        </label>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={handleAgregarNuevaSucursal}
                                style={{
                                    // display: accesoBotonNuevo,
                                    marginRight: '2px'
                                }}
                                title="Seleccionar una sucursal y agregarlo para que este cupon aplique en esa sucursal "
                            >
                                AGREGAR NUEVA SUCURSAL
                            </button>
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                                style={{
                                    marginRight: '2px'
                                }}
                            >
                                ATRAS
                            </button>
                        </div>
                    </div>
                    <Grid rows={listElements} headers={headers} />
                    <ModalConfirm
                        isOpen={modalConfirmarDeshabilitarVisible}
                        onCancel={() => { setModalConfirmarDeshabilitarVisible(false); }}
                        onConfirm={handleConfirmModal}
                        message="¿Estás seguro de deshabilitar aplicar cupon a esta sucursal?"
                    />
                    <ModalAddBranchCoupon
                        isOpen={modalAddBranch}
                        onCancel={() => { setModalAddBranch(false); }}
                        listBranches={listBranches}
                        setBranchSelected={setBranchSelected}
                        branchSelected={branchSelected}
                        title={"Seleccione Sucursal"}
                        onConfirm={handleConfirmModalAddBranch}
                    />
                </div>
            );
        } catch (err) {
            console.log('ManageCurrency render err');
            console.log(err);
            return (
                <div><label>{JSON.stringify(err)}</label></div>
            );
        }
    }

    return render();
}
