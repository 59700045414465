import { DELETE, GET, JWT_EXPIRED, POST, PUT, STRING_ERROR, STRING_FAILURE, STRING_SUCCESS, } from "../utils/const/http-const";
import { getHeaders } from "./utils";
import { getBaseUrl } from "./env/env";
export async function loginWeb(obj) {
  try {
    let response = { error: true, message: '' };
    const { message, code, body } = await fetch(
      getBaseUrl() + "/authentication/login-web",
      {
        headers: getHeaders(false),
        method: POST,
        body: JSON.stringify(obj),
      }
    ).then((res) => res.json());
    console.log("message");
    console.log(message);
    console.log("code");
    console.log(code);
    console.log("body");
    console.log(body);
    if (code === STRING_ERROR || code === STRING_FAILURE) {
      response.error = true;
      response.message = message;
    }
    if (code === STRING_SUCCESS) {
      response.error = false;
      response.body = body;
    }
    return response;
  } catch (err) {
    console.log("loginWeb err");
    console.log(err);
    return { error: true, message: err };
  }
}
