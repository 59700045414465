import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const";
import "../../../style/style-cargando.css";
import { createCurrency, editCurrency } from "../../../api/api-currency";
import cargandoCard from "../../../components/cargando-card";

export default function Currency() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [element, setElement] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setElement(data.params);
        setTitle(data.title);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('init err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setElement({ ...element, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleSaveClick = async () => {
    // console.log('handleSaveClick');
    // lógica para guardar los cambios
    // console.log('element');
    // console.log(element);
    let objEnviar = {};
    objEnviar.description = element.description;
    objEnviar.abbreviation = element.abbreviation;
    objEnviar.id = element.id;
    objEnviar.note = element.note;
    if (element.id === 0) {
      // console.log('crear correo');
      const created = await createCurrency(objEnviar);
      // console.log('created');
      // console.log(created);
      if (created === true) {
        alert('Se creo correctamente');
        handleCancelClick();
      }
      if (created === false) {
        alert('No se pudo crear');
      }
    }
    if (element.id !== 0) {
      // console.log('actualizar correo');
      const modified = await editCurrency(objEnviar);
      // console.log('modified');
      // console.log(modified);
      if (modified === true) {
        alert('Se modifico correctamente');
        handleCancelClick();
      }
      if (modified === false) {
        alert('No se pudo modificar');
      }
    }
  };

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && element === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {title}
            </h2>
            <div className="mt-6 bg-white py-6 px-4 shadow-md rounded-lg sm:px-10">
              <div className="space-y-6">

                <div>
                  <label
                    htmlFor="description"
                    className="block text-md font-medium text-gray-700"
                  >
                    DESCRIPCION
                  </label>
                  <div className="mt-1">
                    <input
                      id="description"
                      name="description"
                      type="text"
                      value={element.description}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={127}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="abbreviation"
                    className="block text-md font-medium text-gray-700"
                  >
                    ABREVIATURA
                  </label>
                  <div className="mt-1">
                    <input
                      id="abbreviation"
                      name="abbreviation"
                      type="text"
                      value={element.abbreviation}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={5}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="note"
                    className="block text-md font-medium text-gray-700"
                  >
                    NOTA
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="note"
                      name="note"
                      type="text"
                      value={element.note}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={1023}
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_CANCELAR}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_ACEPTAR}
                      onClick={handleSaveClick}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log('currency render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
