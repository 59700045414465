import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const";
import "../../../style/style-cargando.css";
import cargandoCard from "../../../components/cargando-card";
import { CheckCircle, ChevronLeft, ChevronsLeft, Facebook, PlusCircle, Save, Trash, Trash2, X, XCircle } from "feather-icons-react/build/IconComponents";
import { getCompanyInfo, updateEmpresa } from "../../../api/api-empresa.js";
import { GlobeAltIcon, TrashIcon } from "@heroicons/react/24/outline";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { getListConcept } from "../../../api/api-concept";
import Select from "react-select";
import { crearSectorCompany, disableSectorCompany, getListSectorCompany } from "../../../api/api-sector-company.js";

// Ensure default marker icon is loaded
L.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

export default function InfoCompany() {
    // const location = useLocation();
    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [objectSelected, setObjectSelected] = useState(null);
    const [isHoveredBackButton, setIsHoveredBackButton] = useState(false);
    const [isHoveredCancelButton, setIsHoveredCancelButton] = useState(false);
    const [isHoveredSaveButton, setIsHoveredSaveButton] = useState(false);

    const [position, setPosition] = useState(null); // Marker position (lat, lng)
    const [location, setLocation] = useState({ latitude: -17.783420, longitude: -63.181049 });// Inputs for lat and lng

    const [listSectorCompany, setListSectorCompany] = useState([]);
    const [sectorCompanySelected, setSectorCompanySelected] = useState(null);

    const [listSectorCompanyDataBase, setListSectorCompanyDataBase] = useState([]);
    useEffect(() => {
        // console.log("entro a Correos");
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const companyInfo = await getCompanyInfo();
            console.log('companyInfo');
            console.log(companyInfo);
            setObjectSelected(companyInfo);
            if (companyInfo.latitude === null && companyInfo.longitude === null) {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLocation({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                        },
                        (error) => {
                            console.log('error location');
                            console.log(error);
                        }
                    );
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }
            } else {
                setPosition([companyInfo.latitude, companyInfo.longitude]);
                setLocation({
                    latitude: companyInfo.latitude,
                    longitude: companyInfo.longitude
                });
            }

            const listSectorComp = await getListConcept({ group: 6 }, true);
            setListSectorCompany(listSectorComp);

            getSectoCompanyDataBase();
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    };

    const getSectoCompanyDataBase = async () => {
        try {
            const list = await getListSectorCompany();
            setListSectorCompanyDataBase(list);
        } catch (err) {
            console.log('getSectoCompanyDataBase err');
            console.log(err);
        }
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setObjectSelected((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleUpdateClick = async () => {
        try {
            // console.log('handleUpdateClick');
            // lógica para guardar los cambios
            console.log('objectSelected');
            console.log(objectSelected);
            // return;
            const response = await updateEmpresa(objectSelected);
            console.log('response');
            console.log(response);
            if (response.error === true) {
                alert(response.message + "\n" + 'No se pudo actualizar');
            }
            if (response.error === false) {
                alert('Se actualizo correctamente');
                handleAtrasClick();
            }

        } catch (err) {
            console.log('handleUpdateClick');
            console.log(err);
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    }

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                setPosition([lat, lng]);
                setLocation({ latitude: lat.toFixed(10), longitude: lng.toFixed(10) });
                setObjectSelected({ ...objectSelected, latitude: lat.toFixed(10), longitude: lng.toFixed(10) });
            },
        });

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You clicked here</Popup>
            </Marker>
        );
    };

    const handleGuardarRubro = async () => {
        try {
            // console.log('sectorCompanySelected ');
            // console.log(sectorCompanySelected);
            if (sectorCompanySelected !== null) {
                if (listSectorCompanyDataBase.length > 0) {
                    const exist = listSectorCompanyDataBase.find(e => e.idSectorCompany === sectorCompanySelected.value);
                    if (exist !== undefined) {
                        alert("El rubro ya se encuentra agregado");
                        return;
                    }
                }
                let objSend = {};
                objSend.idSectorCompany = sectorCompanySelected.value;
                const response = await crearSectorCompany(objSend);
                // console.log('response');
                // console.log(response);
                if (response.error === true) {
                    alert(response.message + "\n" + 'No se pudo agregar');
                }
                if (response.error === false) {
                    alert('Se agrego correctamente');
                    getSectoCompanyDataBase();
                }
            }
            if (sectorCompanySelected === null) {
                alert('Debe seleccionar un rubro para ser agregado');
            }
        } catch (err) {
            console.log('handleGuardarRubro err');
            console.log(err);
        }
    };

    const deleteSectorCompany = async (item) => {
        try {
            // console.log('deleteSectorCompany');
            // console.log(item);
            let objSend = {};
            objSend.id = item.id
            const response = await disableSectorCompany(objSend);
            // console.log('response');
            // console.log(response);
            if (response.error === true) {
                alert(response.message + "\n" + 'No se pudo eliminar');
            }
            if (response.error === false) {
                alert('Se elimino correctamente');
                getSectoCompanyDataBase();
            }
        } catch (err) {
            console.log('deleteSectorCompany err');
            console.log(err);
        }
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            return (
                <div className="min-h-screen bg-white-100 flex flex-col  py-6 xs:px-6 sm:px-6 md:px-6 lg:px-8">
                    <div className="flex justify-between">
                        <label className="font-bold text-gray-700">DATOS EMPRESA</label>
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleAtrasClick}
                            onMouseEnter={() => setIsHoveredBackButton(true)}
                            onMouseLeave={() => setIsHoveredBackButton(false)}
                        >
                            {isHoveredBackButton ? (
                                <ChevronsLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <ChevronLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            ATRAS
                        </button>
                    </div>
                    <div className="grid grid-cols-12 gap-4">

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="nit">
                                NIT
                            </label>
                            <input
                                type="number"
                                name="nit"
                                value={objectSelected.nit}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Enter company email"
                                readOnly
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            {/* Company Name */}
                            <label className="block text-sm font-medium text-gray-700" htmlFor="nameCompany">
                                Nombre Empresa
                            </label>
                            <input
                                type="text"
                                name="nameCompany"
                                value={objectSelected.nameCompany}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Nombre Empresa"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="phoneCompany">
                                Número Telefono
                            </label>
                            <input
                                type="tel"
                                name="phoneCompany"
                                value={objectSelected.phoneCompany}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Número Telefono"
                                required
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="addressCompany">
                                Dirección
                            </label>
                            <input
                                type="text"
                                name="addressCompany"
                                value={objectSelected.addressCompany}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Dirección"
                                required
                            />
                        </div>

                    </div>
                    <br />
                    <hr className="mt-4 mb-4" />
                    {/* RRSS */}
                    <label className="block text-sm font-bold text-gray-700">
                        Redes Sociales
                    </label>
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="flex items-center text-sm font-medium text-gray-700" htmlFor="webSiteUrl">
                                <GlobeAltIcon className="mr-2" width={24} height={24} />Web Site URL
                            </label>
                            <input
                                type="url"
                                name="webSiteUrl"
                                value={objectSelected.webSiteUrl}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="https://"
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="flex items-center text-sm font-medium text-gray-700" htmlFor="facebookUrl">
                                <Facebook className="mr-2" />Facebook
                            </label>
                            <input
                                type="url"
                                name="facebookUrl"
                                value={objectSelected.facebookUrl}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="https://"
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="flex items-center text-sm font-medium text-gray-700" htmlFor="tiktokUrl">
                                <GlobeAltIcon className="mr-2" width={24} height={24} />TikTok
                            </label>
                            <input
                                type="url"
                                name="tiktokUrl"
                                value={objectSelected.tiktokUrl}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="https://"
                            />
                        </div>
                    </div>
                    <hr className="mt-4 mb-4" />
                    {/* END RRSS */}
                    {/* SECTOR COMPANY */}
                    <label className="block text-sm font-bold text-gray-700">
                        Rubro Empresa
                    </label>
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
                            <label className="block text-gray-700 font-bold mb-2">Seleccione Sucursales<span className="font-normal text-sm">(Los rubros seleccionados se guardan automaticamente)</span></label>
                            <Select
                                options={listSectorCompany}
                                isSearchable={true}
                                value={sectorCompanySelected}
                                onChange={(event) => {
                                    setSectorCompanySelected(event);
                                }}
                                placeholder="Seleccione Rubro"
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex justify-end items-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleGuardarRubro}
                                title="Agregar Rubro"
                            >
                                <PlusCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                                AGREGAR
                            </button>
                        </div>
                    </div>
                    <div className="pt-2">
                        {listSectorCompanyDataBase.map((item) => {
                            // console.log('item');
                            // console.log(item);
                            return (
                                <div key={item.id} className="inline-flex items-center p-2 ml-2 mr-2 rounded-md hover:bg-gray-200 transition-colors shadow-md hover:shadow-md">
                                    {item.description}
                                    <button
                                        onClick={() => { deleteSectorCompany(item); }}
                                        className="ml-2 text-red-600"
                                        title="Eliminar Rubro"
                                    >
                                        <Trash2 />
                                    </button>
                                </div>

                            );
                        })}
                    </div>
                    <hr className="mt-4 mb-4" />
                    {/* END SECTOR COMPANY */}
                    <label className="block text-sm font-bold text-gray-700" htmlFor="websiteUrl">
                        Información de Contacto
                    </label>
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="nameContact">
                                Nombre Contacto
                            </label>
                            <input
                                type="text"
                                name="nameContact"
                                value={objectSelected.nameContact}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Nombre Contacto"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="emailContact">
                                Email Contacto
                            </label>
                            <input
                                type="email"
                                name="emailContact"
                                value={objectSelected.emailContact}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Email Contacto"
                                required
                            />
                        </div>
                    </div>
                    <hr className="mt-4 mb-4" />
                    <label className="block text-sm font-bold text-gray-700" htmlFor="websiteUrl">
                        Coordenadas
                    </label>
                    <br />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="latitude">
                                Latitud
                            </label>
                            <input
                                type="text"
                                name="latitude"
                                value={objectSelected.latitude}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Latitud"
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="block text-sm font-medium text-gray-700" htmlFor="longitude">
                                Longitud
                            </label>
                            <input
                                type="text"
                                name="longitude"
                                value={objectSelected.longitude}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                placeholder="Longitud"
                                required
                            />
                        </div>
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12">
                            <MapContainer center={[location.latitude, location.longitude]} zoom={13} style={{ height: "50vh", width: "100%" }}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                {/* <Marker position={position}>
                                    <Popup>
                                        A marker in London.
                                    </Popup>
                                </Marker> */}
                                <LocationMarker />
                            </MapContainer>
                        </div>
                    </div>
                    <br />
                    <div className="flex justify-between bg-white shadow-lg pt-4">
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_CANCELAR}
                            onClick={handleAtrasClick}
                            onMouseEnter={() => setIsHoveredCancelButton(true)}
                            onMouseLeave={() => setIsHoveredCancelButton(false)}
                        >
                            {isHoveredCancelButton ? (
                                <XCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <X className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            CANCELAR
                        </button>
                        <button
                            type="button"
                            className={ESTILO_BOTON_GENERAL_ACEPTAR}
                            onClick={handleUpdateClick}
                            onMouseEnter={() => setIsHoveredSaveButton(true)}
                            onMouseLeave={() => setIsHoveredSaveButton(false)}
                        >
                            {isHoveredSaveButton ? (
                                <CheckCircle className="mr-2 transition-transform duration-500 ease-in-out" />
                            ) : (
                                <Save className="mr-2 transition-transform duration-500 ease-in-out" />
                            )}
                            ACTUALIZAR
                        </button>
                    </div>
                </div>
            );
        } catch (err) {
            console.log('ModificarCorreos render err');
            console.log(err);
            return (
                <div>
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}