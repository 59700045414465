// Define las constantes para los entornos
const DEV = 'development';
const PROD = 'production';

// Define las URLs para los entornos
const DEV_URL = 'http://localhost:8929/api';
const PROD_URL = 'https://connectmeuni.com/api-connectme/api';

// Determina el entorno en base a una variable global o predeterminada
const ENV = DEV;

export const getBaseUrl = () => {
    // if (ENV !== PROD) {
    //     return DEV_URL;
    // }
    return PROD_URL;
};