const Module = (module) => {
  try {
    let ruta = module.href;

    return (
      <div
        className="w-full min-w-[390px] md:w-1/2 lg:w-1/4 p-4"
        key={module.id}
      >
        <a href={`${ruta}`}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              background: `${module.color}`,
              height: 100,
              borderRadius: 10,
              //minWidth: "fit-content",
              //minHeight: "fit-content",
            }}
          >
            <div
              style={{
                width: "20%",
                justifyContent: "center",
                display: "flex",
              }}
              className="px-2 min-w-[64px] mx-2"
            >
              {module.iconHero === false || module.iconHero === undefined ? (
                <img
                  style={{ objectFit: "contain" }}
                  src={module.icono}
                  width="64"
                  height="64"
                  alt=""
                  className="min-w-[64px] min-h-[64px]"
                />
              ) : (
                <>{module.icono}</>
              )}
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                marginLeft: 5,
                width: "80%",
                //borderLeft: "1px white solid",
              }}
            >
              <div className="my-auto px-5">
                <div>
                  <label
                    style={{
                      fontWeight: "bold",
                    }}
                    className="text-gray-600"
                  >
                    {module.titulo}
                  </label>
                </div>
                <div>
                  <label style={{ fontSize: "14px" }} className="text-gray-600">
                    {module.descripcion}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  } catch (err) {
    console.log("Module err");
    console.log(err);
    return (
      <div>
        <label>{JSON.stringify(err)}</label>
      </div>
    );
  }
};

export default Module;
