import Select from "react-select";

const ModalAddBranchCoupon = ({ isOpen, onCancel, onConfirm, title, listBranches, branchSelected, setBranchSelected, }) => {

    return (
        <>
            {isOpen && (
                <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
                    <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="relative py-4 text-left px-6">
                            <div className="mb-4">
                                <p className="text-xl">{title}</p>
                            </div>
                            <Select
                                options={listBranches}
                                isSearchable={true}
                                isClearable={true}
                                value={branchSelected}
                                onChange={(event) => {
                                    setBranchSelected(event);
                                }}
                                placeholder="Seleccione"
                                className="z-50 relative"  // Ensure dropdown is above other elements
                                menuPortalTarget={document.body}  // Render dropdown in the body
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}  // Ensure high z-index for the dropdown
                            />
                            <div className="flex justify-end mt-4">
                                <button
                                    className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={onCancel}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={onConfirm}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            )}
        </>
    );
}

export default ModalAddBranchCoupon;