export default function Footer() {
    return (
        <section>
            <footer class="bg-gray-200 text-white py-4 px-3">
                <div class="container mx-auto flex flex-wrap items-center justify-between">
                    <div class="w-full md:w-1/2 md:text-center md:mb-4 mb-8">
                        <p class="text-xs text-gray-400 md:text-sm">Copyright 2024 &copy; All Rights Reserved</p>
                    </div>
                    <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
                        <ul class="list-reset flex justify-center flex-wrap text-xs md:text-sm gap-3">
                            <li><a href="#contactUs" class="text-gray-400 hover:text-white">Contact</a></li>
                            <li class="mx-4"><a href="/privacy" class="text-gray-400 hover:text-white">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </section>
    );
}